import React from "react";
import theme from "theme";
import { Theme, Text, Image, Section, Box, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про нас | Pedal Pioneers
			</title>
			<meta name={"description"} content={"Розпочніть свою пригоду"} />
			<meta property={"og:title"} content={"Про нас | Pedal Pioneers"} />
			<meta property={"og:description"} content={"Розпочніть свою пригоду"} />
			<meta property={"og:image"} content={"https://movixenon.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://movixenon.com/img/bicycle.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://movixenon.com/img/bicycle.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://movixenon.com/img/bicycle.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://movixenon.com/img/bicycle.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://movixenon.com/img/bicycle.png"} />
			<meta name={"msapplication-TileImage"} content={"https://movixenon.com/img/bicycle.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 40px 100px 40px" background="--color-light" sm-padding="40px 20px 40px 20px" quarkly-title="Images-23">
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="20px"
				max-width="none"
				width="100%"
				lg-flex-direction="column"
				lg-align-items="flex-end"
				sm-min-width="280px"
			/>
			<Text
				color="--darkL2"
				margin="0px 0px 0px 0px"
				font="--headline2"
				width="20%"
				text-align="right"
				lg-width="100%"
				lg-text-align="left"
				lg-font="--headline3"
				sm-margin="16px 0px 0px 0px"
				letter-spacing="2px"
				text-transform="uppercase"
			>
				Про Нас
			</Text>
			<Image
				src="https://movixenon.com/img/7.jpg"
				display="block"
				width="60%"
				lg-order="-1"
				sm-width="100%"
			/>
			<Text
				color="--darkL2"
				margin="0px 0px 0px 0px"
				font="--lead"
				width="20%"
				align-self="flex-end"
				lg-width="100%"
				lg-max-width="540px"
				lg-align-self="auto"
			>
				Для Pedal Pioneers велосипед - це більше, ніж просто засіб пересування, це двері до нових вражень. Наша місія полягає в тому, щоб дарувати кожному клієнту гострі відчуття від пригод і прогулянок на свіжому повітрі, крутячи педалі за раз. Незалежно від того, досвідчений ви велосипедист чи новачок, який прагне дослідити світ, ми допоможемо вам на кожному кроці.
			</Text>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Text margin="0px 0px 90px 0px" text-align="center" font="normal 600 42px/1.2 --fontFamily-sans" md-margin="0px 0px 60px 0px">
				Наш автопарк
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
					Наш автопарк - це серце компанії Pedal Pioneers. У нас представлений широкий асортимент велосипедів, від чутливих шосейних до надійних гірських, кожна модель відібрана за якістю та продуктивністю. Ми постійно оновлюємо наш парк велосипедів, впроваджуючи останні досягнення велосипедних технологій, щоб гарантувати, що ви будете їздити тільки на найкращому.
					</Text>
				</Box>
				<Image src="https://movixenon.com/img/8.jpg" display="block" width="100%" md-order="-1" />
				<Image src="https://movixenon.com/img/9.jpg" display="block" width="100%" />
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 30px 0px 20px"
					align-items="flex-start"
					md-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
					Обслуговування велосипедів на високому рівні: Кожен велосипед проходить ретельну перевірку та регулярне технічне обслуговування для забезпечення вашої безпеки та комфорту.
					<br/><br/>
Різноманітні варіанти для кожного велосипедиста: Від повсякденних міських круїзерів до високопродуктивних спортивних моделей - наш асортимент задовольнить будь-який тип велосипедиста.
					<br/><br/>
Екологічно чисті пригоди: Приєднуйтесь до зеленого способу життя з велосипедами, які забезпечують екологічно чистий і здоровий спосіб подорожувати.

					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
					Приєднуйтесь до нашої велоспільноти
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
					Кожна поїздка з Pedal Pioneers - це крок до створення власної велосипедної історії. Тут ви не просто орендуєте велосипед - ви стаєте частиною спільноти, яка цінує свободу та пригоди. Створюйте свою подорож разом з нами, діліться досвідом та надихайтеся різноманітними маршрутами та пунктами призначення, до яких вас можуть відвезти наші велосипеди.
					</Text>
					<Button
						href="/contact"
						type="link"
						text-decoration-line="initial"
						background="#0438cd"
						padding="11px 28px 11px 28px"
						border-radius="25px"
					>
						Готуйтеся до наступної пригоди
					</Button>
				</Box>
				<Image src="https://movixenon.com/img/1.jpg" display="block" width="100%" md-order="-1" />
			</Box>
		</Section>
		<Components.Footer />
		<Components.FooterNav />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});